import asyncComponent from 'Demora/AsyncComponent';

const blog = asyncComponent(() =>
	import(/* webpackChunkName: "blog" */ '../../js/components/Blog/blog')
);

const PublicCampaignPost = asyncComponent(() =>
	import(
		/* webpackChunkName: "PublicCampaignPost" */ '../../js/components/public_campaign_post/PublicCampaignPost'
	)
);

import SocialRoutes from '../../../../vendor/z5internet/react-social/src/resources/assets/js/src/app/NoAuth/Routes';

const viewPlan = asyncComponent(() =>
	import(
		/* webpackChunkName: "PublicCampaignPost" */ '../../js/components/plan/viewPlan'
	)
);

import WebinarRoutes from '../../../../vendor/z5internet/webinar/src/resources/assets/js/components/NoAuth/Routes';

export default SocialRoutes.concat(
	WebinarRoutes.concat([
		{
			path: '/blog/:bid?',
			component: blog,
		},
		{
			path: '/public_campaign_post/:pid',
			component: PublicCampaignPost,
		},
		{
			path: '/settings/emailSettings1',
			component: asyncComponent(() =>
				import(
					/* webpackChunkName: "emailOptions" */ '../../js/components/email/emailOptions'
				)
			),
		},
		{
			path: '/users/home/cb/return/return1.php',
			component: asyncComponent(() =>
				import(
					/* webpackChunkName: "upgReturn1" */ '../../js/components/upgrade/pay/Upg2'
				)
			),
		},
		{
			path: '/viewPlan/:id/:code',
			component: viewPlan,
		},
	])
);
