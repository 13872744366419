import asyncComponent from 'Demora/AsyncComponent';

const profile = asyncComponent(() =>
	import(/* webpackChunkName: "prot-auth-profile" */ '../Auth/Profile/Profile')
);

const showPost = asyncComponent(() =>
	import(/* webpackChunkName: "prot-auth-post" */ '../Auth/Profile/ShowPost')
);

export default [
	{
		path: '/profile/:obj?/:tab?',
		component: profile,
	},
	{
		path: '/post/:pid',
		component: showPost,
	},
];
