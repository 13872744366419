import asyncComponent from 'Demora/AsyncComponent';

export default [
	{
		path: '/webinar/register/:webinarId',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "webinar_register" */ './Register/Register')
		),
	},
	{
		path: '/webinar/view/:attendeeId/a',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "webinar_view" */ './View/View')
		),
	},
];
