import Cookies from 'js-cookie';

import { v4 as uuidv4 } from 'uuid';

import iosInnerHeight from 'ios-inner-height';

export default class recordStats {

	visitCookieName = 'z5statsvisit';

	constructor(id) {

		this.state = {
			id: null,
			firstRecord: false,
			pageID: null,
			processingRecord: false,
			processingUpdate: false,
			visitID: null,
			visitorID: null,
			windowFocused: true,
			windowVisible: true,
		};

		this.setUserID(id);

		this.setVisitorID();
		this.setVisitID();
		this.setPageID();

		this.changePage();

		// event lost focus
		// event gain focus

		// event scroll

		window.onbeforeunload = this.beforeunload;

		window.addEventListener('blur', this.windowLostFocus);
		window.addEventListener('focus', this.windowGainedFocus);
		document.addEventListener('visibilitychange', this.windowVisible);

		setInterval(this.update, 5000);

	}

	getUserID = () => {
		return this.state.id;
	}

	setUserID = (id) => {

		this.setPageID();

		this.setState({
			id,
		});
	}

	getVisitorID = () => {
		return this.state.visitorID;
	}

	setVisitorID = () => {
		const cookieName = 'z5statsvisitor';

		let visitorID = Cookies.get(cookieName);

		if (!visitorID) {
			visitorID = uuidv4();
			Cookies.set(cookieName, visitorID, {expires: 365 * 10});
		}
		this.setState({
			visitorID,
		});
	}

	getVisitID = () => {
		return this.state.visitID;
	}

	setVisitID = () => {
		const visitID = Cookies.get(this.visitCookieName) ?? uuidv4();

		this.setState({
			visitID,
		});

		this.refreshVisitIDCookie();

	}

	refreshVisitIDCookie = () => {
		Cookies.set(this.visitCookieName, this.state.visitID, {expires: 1/48}); // (30 minutes)
	}

	getPageID = () => {
		return this.state.pageID;
	}

	setPageID = () => {
		this.setState({
			pageID: uuidv4(),
		});
	}

	getDeviceDimensions = () => {
		return {
			wh: iosInnerHeight(),
			ww: window.innerWidth,
			sw: screen.width,
			sh: screen.height,
		};
	}

	changePage = () => {
		this.setPageID();
		this.send({});
	}

	getHeaders() {
		return {
			'X-Requested-With': 'XMLHttpRequest',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		};
	}

	getTimestamp() {
		return Math.floor(Date.now() / 1000);
	}

	windowVisible = () => {
		const vis = document.visibilityState === 'visible';

		if (
			(!vis) ||
			(vis && !this.state.windowVisible)
		) {
			this.setState({
				windowVisible: vis,
			});
		}
	};

	windowLostFocus = () => {
		this.setState({
			windowFocused: false,
		});
	}

	windowGainedFocus = () => {
		if (!this.state.windowFocused) {
			this.setState({
				windowFocused: true,
			});
		}
	}

	setState = (state) => {
		this.state = Object.assign(this.state, state);
	}

	beforeunload = (e) => {

		this.update();

	}

	getDomain = () => {
		if (document.location.port == 8080) {
			return 'http://cashjuice.test:8000'
		}

		return '';
	}

	update = () => {

		if (this.state.processingRecord || !this.state.firstRecord) {
			return;
		}

		if (this.state.processingUpdate) {
			return;
		}

		this.setState({
			processingUpdate: true,
		});

		this.refreshVisitIDCookie();

		const data = {};

		data.visitID = this.getVisitID();
		data.visitorID = this.getVisitorID();
		data.pageID = this.getPageID();

		data.t = this.getTimestamp();

		return fetch(this.getDomain()+'/data/z5-stats/update', {
			method: 'put',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		}).then(data => {
			this.setState({
				processingUpdate: false,
			});
		})
	}

	send = (data) => {

		this.update();

		this.setState({
			processingRecord: true,
		});

		data.url = document.location;
		data.visitID = this.getVisitID();
		data.visitorID = this.getVisitorID();
		data.pageID = this.getPageID();

		data.d = this.getDeviceDimensions();
		data.t = this.getTimestamp();

		data.u = this.getUserID();

		data.r = document.referrer;

		return fetch(this.getDomain()+'/data/z5-stats/record', {
			method: 'post',
			headers: this.getHeaders(),
			body: JSON.stringify(data),
		}).then(data => {
			this.setState({
				firstRecord: true,
				processingRecord: false,
			});
		})
	}
}