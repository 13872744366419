import asyncComponent from 'Demora/AsyncComponent';

const profile = asyncComponent(() =>
	import(/* webpackChunkName: "prot-auth-profile" */ './Profile/Profile')
);

const tags = asyncComponent(() =>
	import(/* webpackChunkName: "prot-auth-tags" */ './Profile/Tags/Tags')
);

export default [
	{
		path: '/profile',
		component: profile,
	},
	{
		path: '/tags',
		component: tags,
	},
	{
		path: '/tags/:tag',
		component: tags,
	},
	{
		path: '/BookmarkedPosts',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-favoritePosts" */ './Profile/BookmarkedPosts/BookmarkedPosts'
			)
		),
	},
	{
		path: '/groups',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-groups" */ './Profile/Groups/Groups'
			)
		),
	},
	{
		path: '/groups/:code/join',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-groupJoin" */ './Profile/Groups/GroupJoin'
			)
		),
	},
	{
		path: '/userSearch',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-notifications" */ './Relationships/UserSearch'
			)
		),
	},
];
